"use client";

import React from "react";
import { cn } from "../../../helpers/classNames";
import { debug } from "../../../helpers/debug";
import { getWindow } from "../../../helpers/getWindow";
import { useCountry, usePageType } from "../../ApplicationState/hooks";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import { useStickyAdOffsets } from "../hooks";
import type { AdSlotsClickPerformance } from "../types";

type Props = {
    slot: AdSlotsClickPerformance;
    className?: string;
};

export const reloadClickPerformanceSlots = (
    slotsToReload?: Array<AdSlotsClickPerformance>
): void => {
    const window = getWindow();
    if (window?.adHandler?.cmd) {
        if (slotsToReload !== undefined && slotsToReload.length > 0) {
            window.adHandler.cmd.push(function () {
                if (typeof window.adHandler?.service?.destroy === "function") {
                    slotsToReload.forEach((slot) => {
                        const adUnit =
                            window.adHandler?.service?.getAdUnit(slot);
                        if (adUnit) {
                            // eslint-disable-next-line testing-library/no-debugging-utils
                            debug(
                                `[CP] destroy [${slot}], time:`,
                                new Date().getTime()
                            );
                            adUnit.destroy();

                            if (
                                slot === "wetter_overlay_performancerectangle1"
                            ) {
                                adUnit.lazy.status = "wait";
                            }
                        }
                    });
                }
            });
        } else {
            window.adHandler.cmd.push(function () {
                if (typeof window.adHandler?.service?.destroy === "function") {
                    // eslint-disable-next-line testing-library/no-debugging-utils
                    debug("[CP] destroy [all], time:", new Date().getTime());
                    window.adHandler.service.destroy();
                }
            });
        }

        if (typeof window.adHandler.service?.request === "function") {
            // eslint-disable-next-line testing-library/no-debugging-utils
            debug("[CP] request, time:", new Date().getTime());
            window.adHandler.service.request();
            window.setTimeout(() => {
                window.adHandler?.service?.request();
            }, 100);
        }
    }
};

export const ClickPerformance: React.FC<Props> = ({
    slot,
    className,
}: Props) => {
    const consent = useConsent();
    const isPay = useIsPay();
    const { top } = useStickyAdOffsets();
    const country = useCountry();
    const pageType = usePageType();

    try {
        if (!consent || isPay) {
            return null;
        }

        return (
            <div
                id={slot}
                className={cn(
                    className,
                    "ad-flex",
                    "ad-fix-sticky",
                    top !== "0" && country === "de" && "top"
                )}
                data-testid="cp-adslot"
                data-page-type={
                    pageType?.isForecastTodayPage
                        ? "Refresh_REACT_today"
                        : "notset"
                }
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
