"use client";

import React from "react";
import type { Nullable } from "../../../@types/generic";
import { cn } from "../../../helpers/classNames";
import { getWindow } from "../../../helpers/getWindow";
import { useCountry } from "../../ApplicationState/hooks";
import { useConsent, useIsPay } from "../../Consent/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import { useStickyAdOffsets } from "../hooks";
import type { AdSlots } from "../types";
import { SomInRead } from "./SomInRead";
import { SomMBanner } from "./SomMBanner";
import styles from "./styles.module.scss";

type Props = {
    slot: AdSlots;
    stickyTime?: number; // seconds
    element?: Nullable<HTMLDivElement>;
};

export const reloadSomSlots = (): void => {
    const window = getWindow();
    if (window?.somtag) {
        window.somtag.cmd("reloadDisplaySlots", []);
    }
};

export const Som: React.FC<Props> = ({
    slot,
    stickyTime = 0,
    element,
}: Props) => {
    const id = `somtag-container-${slot}`;
    const consent = useConsent();
    const isPay = useIsPay();
    const { top } = useStickyAdOffsets();
    const country = useCountry();

    try {
        if (!consent || isPay || country === "de") {
            return null;
        }

        if (slot === "mbanner1" && element) {
            return (
                <SomMBanner
                    slot={slot}
                    stickyTime={stickyTime}
                    element={element}
                />
            );
        }

        if (slot === "minread1") {
            return <SomInRead slot={slot} />;
        }

        if (slot === "rectangle1") {
            return (
                <div
                    id={id}
                    className={cn(
                        styles.adSlotSizeLarge,
                        "ad-flex",
                        "ad-fix-sticky",
                        top !== "0" && "top"
                    )}
                />
            );
        }

        return null;
    } catch (e) {
        sendError(e);
        return null;
    }
};
