"use client";

import React, { useCallback, useEffect, useState } from "react";
import type { Nullable } from "../../../@types/generic";
import { cn } from "../../../helpers/classNames";
import { getWindow } from "../../../helpers/getWindow";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import {
    useIsPersistingForbidden,
    usePageType,
} from "../../ApplicationState/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type { AdSlots } from "../types";
import styles from "./styles.module.scss";

type Props = {
    slot: AdSlots;
    stickyTime?: number; // seconds
    className?: string;
    element: Nullable<HTMLDivElement>;
};

export const SomMBanner: React.FC<Props> = ({
    slot,
    stickyTime,
    className,
    element,
}) => {
    const isPersistingForbidden = useIsPersistingForbidden();
    const window = getWindow();
    const [mbannerNode, setMbannerNode] = useState<Nullable<HTMLElement>>(null);
    const observerEntry = useIntersectionObserver(element, {
        threshold: 0.25,
    });
    const [wasSticky, setWasSticky] = useState(false);
    const mbannerRef = useCallback(
        (node: Nullable<HTMLElement>) => setMbannerNode(node),
        []
    );
    const pageType = usePageType();
    const hasBackdrop = window?.document.body.classList.contains("noscroll");

    useEffect(() => {
        if (
            mbannerNode &&
            mbannerNode.innerHTML !== "" &&
            stickyTime &&
            !wasSticky &&
            observerEntry
        ) {
            if (pageType?.isAnyForecastPage) {
                // no sticky'ness for forecast pages
                return;
            }

            if (!observerEntry.isIntersecting && !hasBackdrop) {
                mbannerNode.classList.add(styles.mBannerFixed ?? "");

                window?.setTimeout(() => {
                    mbannerNode.classList.remove(styles.mBannerFixed ?? "");
                    setWasSticky(true);
                }, stickyTime * 1000);
            } else {
                mbannerNode.classList.remove(styles.mBannerFixed ?? "");
            }
        }
    }, [
        window,
        pageType,
        hasBackdrop,
        mbannerNode,
        observerEntry,
        stickyTime,
        wasSticky,
    ]);

    try {
        if (isPersistingForbidden) {
            return null;
        }

        return (
            <div
                className={cn(styles.mBanner, className ?? undefined)}
                id="ad-mbanner1-outer"
                ref={mbannerRef}
                data-testid="mbanner-adslot"
            >
                <div
                    id={`somtag-container-${slot}`}
                    className="ad-flex ad-fix"
                />
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
