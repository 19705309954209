import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { getWindow } from "../../helpers/getWindow";
import { isDachProduct } from "../../helpers/isDachProduct";
import { useLocation } from "../../network/matlocq/queries/Location";
import {
    useCountry,
    useIsMobileSafari,
    useIsPersistingForbidden,
    useIsWebview,
    usePageParams,
    usePageType,
} from "../ApplicationState/hooks";
import { useConsent, useIsPay } from "../Consent/hooks";
import { reloadClickPerformanceSlots } from "./slots/ClickPerformance";
import { reloadSomSlots } from "./slots/Som";
import type {
    AdPageInformation,
    SomCustom,
    UseReloadAds,
    UseStickyAdOffsets,
} from "./types";

export const useAdInformationFromPage = (): AdPageInformation => {
    const country = useCountry();
    const pathname = usePathname();
    const pageType = usePageType();
    const pageParams = usePageParams();
    const { data: location } = useLocation(
        pageParams.code && pageType?.isAnyForecastPage ? pageParams.code : null
    );
    const path = pathname;
    let pathParts = path.replace(/^\/|\/$/g, "").split("/");
    const custom: Array<SomCustom> = [
        { key: "kw", value: [`wetter_${country.toUpperCase()}`] },
    ];
    const content = "content";
    let keywords: Array<string> = [];
    const slots: Array<string> = ["mbanner1", "rectangle1", "minread1"];
    let type = "Beitrag";
    let source = pathname;
    let mode = "common";
    const adunits = {
        adunit1: "m.mew_wetter.com_sd",
        adunit2: "rest",
    };

    // somtag/taxanomy/channels expects 6 values - path parts could be less, so fill with empty strings
    if (pathParts.length < 6) {
        for (let i = pathParts.length; i < 6; ++i) {
            pathParts[i] = "";
        }
    }

    // fill first three items with values if empty
    for (let i = 0; i < 3; ++i) {
        if (pathParts[i] === "") {
            pathParts[i] = path === "/" ? "home" : "other";
        }
    }

    if (pathParts[0]?.toLowerCase() === "wettercom-live") {
        pathParts[0] = "wettertv";
    }

    if (pageType?.isHomePage) {
        type = "Home";
        source = "/home";
    } else if (pageType?.isAnyForecastPage) {
        let alternativePathname;
        if (pageType.isForecastMultiplePage) {
            alternativePathname = `/wetter_aktuell/wettervorhersage/${
                pageType.isForecastWeekendPage
                    ? "wochenend_vorhersage"
                    : `${pageParams.daysMultiple ?? "unknown"}tage`
            }/${location?.admin1.location.isoPath ?? ""}/${
                location?.name ?? ""
            }`;
        } else {
            let daySlug = "heute";

            if (pageType.isForecastTomorrowPage) {
                daySlug = "morgen";
            } else if (
                pageType.isForecastSinglePage &&
                pageParams.daysSingle === "2"
            ) {
                daySlug = "uebermorgen";
            } else if (pageType.isForecastSinglePage) {
                daySlug = `in${pageParams.daysSingle ?? "unknown"}tagen`;
            }

            alternativePathname = `/wetter_aktuell/wettervorhersage/${daySlug}/${
                location?.continent.location.name ?? ""
            }/${
                location?.admin1.location.isoPath?.substring(3) ??
                (location?.country.code ? `${location.country.code}/` : "")
            }/${location?.name ?? ""}`;
        }
        type = "Vorhersage";
        mode = "city";
        keywords = [""];
        source = alternativePathname;
        pathParts = alternativePathname.replace(/^\/|\/$/g, "").split("/");
    } else if (pageType?.isAnyHealthPollenDictionaryPage) {
        type = "Pollenlexikon";
        adunits.adunit2 = "gesundheit";
    } else if (pageType?.isAnyLivecamPage) {
        type = "HD Live Webcams";
    } else if (pageType?.isAnySkiAreasPage) {
        type = "Skigebiete";
    }

    return {
        type,
        source,
        som: {
            content,
            channels: pathParts.slice(0, 6),
            keywords,
            custom,
            slots,
        },
        gam: {
            mode,
            keywords: keywords.join(","),
        },
        cp: {
            adunits,
        },
    };
};

export const useStickyAdOffsets = (): UseStickyAdOffsets => {
    const isPersistingForbidden = useIsPersistingForbidden();
    const isMobileSafari = useIsMobileSafari();
    const isPay = useIsPay();
    const [offsets, setOffsets] = useState({ top: "0", bottom: "0" });
    const isWebview = useIsWebview();
    const country = useCountry();

    // we have to have an useEffect, due to rerenders from SSR/CSR
    useEffect(() => {
        if (
            isDachProduct(country) &&
            !isWebview &&
            !isPay &&
            !isPersistingForbidden
        ) {
            if (isMobileSafari) {
                setOffsets({ top: "75px", bottom: "0" });
            } else {
                setOffsets({ top: "0", bottom: "75px" });
            }
        }
    }, [country, isWebview, isMobileSafari, isPay, isPersistingForbidden]);

    return offsets;
};

export const useReloadAds = (): UseReloadAds => {
    const isPay = useIsPay();
    const consent = useConsent();
    const country = useCountry();
    const isAllowed = consent && !isPay;
    const window = getWindow();
    const [reloadAds, setReloadAds] = useState<UseReloadAds>(undefined);

    useEffect(() => {
        if (window?.adHandler && isAllowed) {
            setReloadAds(
                (): UseReloadAds =>
                    (adSlots): void => {
                        if (country === "at" || country === "ch") {
                            reloadSomSlots();
                        }
                        if (adSlots?.de && country === "de") {
                            reloadClickPerformanceSlots(adSlots.de);
                        }
                        if (adSlots?.at && country === "at") {
                            reloadClickPerformanceSlots(adSlots.at);
                        }
                        if (adSlots?.ch && country === "ch") {
                            reloadClickPerformanceSlots(adSlots.ch);
                        }
                    }
            );
        }
    }, [isAllowed, window?.adHandler, country]);

    return reloadAds;
};
