// @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
import { useEffect, useState } from "react";
import type { Nullable } from "../@types/generic";
import { getWindow } from "../helpers/getWindow";

export const useIntersectionObserver = (
    element: Nullable<HTMLElement> | undefined,
    {
        threshold = 0,
        root = null,
        rootMargin = "0%",
        freezeOnceVisible = false,
    }: IntersectionObserverInit & { freezeOnceVisible?: boolean }
): IntersectionObserverEntry | undefined => {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();
    const frozen = entry?.isIntersecting && freezeOnceVisible;
    const window = getWindow();
    useEffect(() => {
        if (!window?.IntersectionObserver || frozen || !element) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => setEntry(entries[0]),
            { threshold, root, rootMargin }
        );

        observer.observe(element);

        return (): void => observer.disconnect();
    }, [window, element, threshold, root, rootMargin, frozen]);

    return entry;
};
