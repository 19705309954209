"use client";

import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../helpers/classNames";
import { usePageType } from "../../ApplicationState/hooks";
import { sendError } from "../../ErrorBoundary/helpers";
import type { AdSlots } from "../types";
import styles from "./styles.module.scss";

type Props = {
    slot: AdSlots;
};

export const SomInRead: React.FC<Props> = ({ slot }) => {
    const ref = useRef<HTMLDivElement>(null);
    const pageType = usePageType();
    const [height, setHeight] = useState(0);
    const threshold = 250;

    useEffect(() => {
        if (ref.current) {
            const mutationObserver = new MutationObserver((mutationList) => {
                const maxHeight = Math.max(
                    ...mutationList.map(
                        (item) => (item.target as HTMLElement).offsetHeight
                    )
                );

                if (maxHeight > height && maxHeight > threshold) {
                    setHeight(maxHeight);
                }
            });

            mutationObserver.observe(ref.current, {
                attributes: true,
                attributeFilter: ["style"],
                childList: true,
                subtree: true,
            });

            return (): void => {
                mutationObserver.disconnect();
            };
        }
        return (): undefined => undefined;
    }, [height]);

    try {
        return (
            <div
                id={`somtag-container-${slot}`}
                ref={ref}
                className={cn(
                    styles.inRead,
                    pageType?.isAnyForecastPage === true && height > threshold
                        ? styles.inReadWithMargin
                        : undefined
                )}
                style={{
                    maxHeight: `${height.toString()}px`,
                }}
            >
                <div
                    id="ad_inread_container"
                    className={styles.inReadInnerSOM}
                />
                <div id="71M_inreadads" />
                <div id="dspx_scroller" />
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
